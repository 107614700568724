import React from "react";
import styled from "@emotion/styled";

import Descriptor from "../../components/Descriptor/index";
import Image from "../../components/image";
import InnerContainer from "../../components/InnerContainer";

const ItemizedCompoonent = styled.div`
  font-family: ${props => props.theme.fonts.regular};
  background-color: ${props => props.theme.colors.lightPeach};
  padding: 4rem 1.5rem;

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    padding: 6rem;
  }

  /* Container Styles */
  .sectionWrapper {
    @media (min-width: ${props => props.theme.breakpoints.md}) {
      display: flex;
    }
  }
  .leftWrapper {
    @media (min-width: ${props => props.theme.breakpoints.md}) {
      width: 17rem;
      margin-right: 10rem;
    }
  }
  .contentContainer {
    @media (min-width: ${props => props.theme.breakpoints.md}) {
      max-width: 99rem;
    }
    &--single {
      @media (min-width: ${props => props.theme.breakpoints.md}) {
        /* margin-right: 12.586206896%;
        margin-left: 23.189655172%; */
      }
    }
  }

  .pink-title {
    font-size: 4rem;
    line-height: 50px;
    margin-bottom: 4.5rem;
    color: ${props => props.theme.colors.darkPink};

    @media (min-width: ${props => props.theme.breakpoints.md}) {
      font-family: ${props => props.theme.fonts.medium};
      font-size: 6.5rem;
      line-height: 78px;
    }
  }

  .gold-title {
    font-family: ${props => props.theme.fonts.medium};
    font-weight: 500;
    font-size: 3.1rem;
    line-height: 38px;
    color: ${props => props.theme.colors.darkGold};
    margin-bottom: 2rem;

    @media (min-width: ${props => props.theme.breakpoints.md}) {
      font-size: 2.5rem;
      line-height: 39px;
    }
  }

  .black-body-text {
    font-size: 1.8rem;
    line-height: 32px;
    color: ${props => props.theme.colors.offsetBlack};
    margin-bottom: 3.5rem;
  }
`;

const ImageComponent = styled(Image)`
  width: 5.45rem;
  height: 5.45rem;
  margin: 3rem 0;

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    margin: 3rem auto;
  }
`;

const ItemizedListDescriptor = props => {
  return (
    <ItemizedCompoonent className="container-padding">
      <InnerContainer>
        <div className="sectionWrapper">
          <div className="leftWrapper">
            <Descriptor
              title={props.descriptor}
              color={props.descriptorColor}
              alignment={props.underlineAlignment}
            />
            <ImageComponent image={props.image} />
          </div>
          <div className="contentContainer">
            <h2 className="pink-title">What to expect from Brandish</h2>
            <h4 className="gold-title">You won’t be micromanaged</h4>
            <p className="black-body-text">
              We think it’s counterproductive to infantilize high functioning
              intelligent adults so we don’t stand over your shoulder and we
              don’t keep tabs on you.
            </p>
            <h4 className="gold-title">
              You will have flexibility and autonomy
            </h4>
            <p className="black-body-text">
              We want you to figure the best working methods, approaches and
              schedules that work for you and balance them with the needs of the
              agency.
            </p>
            <h4 className="gold-title">You won’t fill out timesheets</h4>
            <p className="black-body-text">
              We don’t bill by the hour because it fundamentally goes against
              the nature of our service as a strategic consultancy. We have
              found that if you want to destroy any relationship then make the
              focal point about money. So we don’t do that and there are no time
              sheets to fill out.
            </p>
            <h4 className="gold-title">You won’t be impeded by hierarchies</h4>
            <p className="black-body-text">
              We are structured but we are not bogged down by hierarchies. We
              want good ideas coming from all directions and we encourage active
              input and participation from everyone across the agency.
            </p>
          </div>
        </div>
      </InnerContainer>
    </ItemizedCompoonent>
  );
};

export default ItemizedListDescriptor;
